

<div class="text-center mt-3 mb-3">
    Conexión a servidor: 
    <span class="text-success font-weight-bold" *ngIf="wsService.socketStatus"><i class="mdi mdi-minus-circle"></i> - Online </span>
    <span class="text-danger font-weight-bold" *ngIf="!wsService.socketStatus"><i class="mdi mdi-minus-circle"></i> - Offline</span>  
</div> 
<div class="container" *ngIf="preguntasActivas >= 1">
    <div class="row">
        <div class="col text-right">
            <h1>{{ segundos }} segundos</h1>
        </div>
    </div><div class="row">
        <div class="col">
            <h1>{{ pregunta }}</h1>
        </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2" >
        <button type="button" class="btn btn-primary w-100 h-100" *ngIf="preguntasActivas >= 1">{{ respuestas[0] }}</button> 
      </div>
      <div class="col-sm-12  col-md-6 col-lg-6 col-xl-6 mt-2" *ngIf="preguntasActivas >= 2">
        <button type="button" class="btn btn-success w-100 h-100" >{{ respuestas[1] }}</button> 
      </div>
      <div class="col-sm-12  col-md-6 col-lg-6 col-xl-6 mt-2" *ngIf="preguntasActivas >= 3">
        <button type="button" class="btn btn-danger w-100 h-100" >{{ respuestas[2] }}</button> 
      </div>
      <div class="col-sm-12  col-md-6 col-lg-6 col-xl-6 mt-2" *ngIf="preguntasActivas >= 4">
        <button type="button" class="btn btn-warning w-100 h-100" >{{ respuestas[3] }}</button> 
      </div> 
      <div class="col-sm-12  col-md-6 col-lg-6 col-xl-6 mt-2" *ngIf="preguntasActivas >= 5">
        <button type="button" class="btn btn-secondary w-100 h-100" >{{ respuestas[4] }}</button> 
      </div> 
      <div class="col-sm-12  col-md-6 col-lg-6 col-xl-6 mt-2" *ngIf="preguntasActivas >= 6">
        <button type="button" class="btn btn-info w-100 h-100" >{{ respuestas[5] }}</button> 
      </div> 
      <div class="col-sm-12  col-md-6 col-lg-6 col-xl-6 mt-2" *ngIf="preguntasActivas >= 7">
        <button type="button" class="btn btn-light w-100 h-100" >{{ respuestas[6] }}</button> 
      </div> 
      <div class="col-sm-12  col-md-6 col-lg-6 col-xl-6 mt-2" *ngIf="preguntasActivas >= 8">
        <button type="button" class="btn btn-dark w-100 h-100" >{{ respuestas[7] }}</button> 
      </div>   
    </div>
  </div>

    <div class="card" *ngIf="preguntasActivas == 0 && encuestaIniciada">
        <div class="card-title text-center mt-3"> 
            <h2>Resultados</h2>
        </div>
        <div class="card-body">
            <div class="text-center"><h3>{{ pregunta || '' }}</h3></div>
            <app-barras #com1 
            [labels]="labels1"
            [data]="data2"
            ></app-barras> 
        </div>
    </div>

    
    <div class="card" *ngIf="!encuestaIniciada">
        <div class="card-title text-center mt-3"> 
            <h2>Iniciando</h2>
        </div>
        <div class="card-body text-center" style="height: 500px">
            Por favor, espera a que se muestren las preguntas.
        </div>
    </div>