import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { WebsocketsService } from '../../services/websockets.service'; 
import { EncuestaService } from '../../services/encuesta.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Pregunta } from '../../models/pregunta.model';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-encuesta-activa',
  templateUrl: './encuesta-activa.component.html',
  styleUrls: ['./encuesta-activa.component.css']
})
export class EncuestaActivaComponent implements OnInit {
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent;
  @Output() onClick = new EventEmitter();
  @ViewChild("com1") com1: any;

  idEncuesta = '';
  preguntas:Pregunta[] = [];
  segundos: number = 0;
  mostradas: string[] = [];

  //Ultima Pregunta 
  ultimaPreguntaPregunta = '';
  ultimaPregunta = '';
  ultimaPreguntaResp = 0;
  ultimaPregunta_data: any = {}; //Datos completos de la pregunta activa
  intervalo;
  
  //Resultados
  public labels1: string[] = ['', '', ''];
  public data1 = [ 0, 0, 0 ];
  public data2 = [{ data: [0, 0, 0, 0, 0, 0, 0], label: '', backgroundColor:  '#6857E6'}];
  public sinContestar = 0;

  constructor( 
    public wsService: WebsocketsService,
    private router: Router,
    private encuestaService: EncuestaService,
    private route: ActivatedRoute,
  ) { 
    this.idEncuesta =  this.route.snapshot.paramMap.get('id'); 
    //this.wsService.emit('activar-encuesta',{ idEncuesta: this.idEncuesta }); 

    this.encuestaService.getPreguntas().subscribe( 
      (data: any) => {  
      // cache our list 
      this.preguntas = [...data];  
      console.log("Guardando las preguntas " , this.preguntas);
    });
    this.encuestaService.getEncuestaActiva().subscribe( 
      (data: any) => {  
      // cache our list 
      this.wsService.idEncuesta = data.idEncuesta;
      this.wsService.idPregunta = data.idPregunta;
    });

    this.wsService.listen('resultados-pregunta').subscribe( (data: any)=> {
      console.log('resultados-pregunta', data );
      this.data1 = [...data];
      this.data2[0].data = [...data];

      try{
        this.com1.updateChart();
      }catch(error){

      }
      
    }, err => {

    });

    this.wsService.listen('encuesta-iniciada').subscribe( (data: any)=> {
      console.log('encuesta-iniciada', data );
      this.data1 = [...data];
      this.data2[0].data = [...data];

      this.com1.updateChart();
    }, err => {

    });
  }

  ngOnInit() {
    console.log("Estatus del socket", this.wsService.socketStatus);
  }
  
  activarPregunta(index, idPregunta, duracion, respuestas){

    this.ultimaPregunta = idPregunta;
    this.ultimaPreguntaResp = Number(respuestas);
    let respuesta = "";

    this.labels1 = [];
    this.ultimaPregunta_data = {};

    this.ultimaPregunta_data = this.preguntas[index]; 
    respuesta =   this.preguntas[index].respuesta ;
    console.log("RESP", this.preguntas[index]);
    let tiempo =   this.preguntas[index].duracion ;
    this.ultimaPreguntaPregunta =   this.preguntas[index].pregunta ;
    //this.ultimaPregunta_data.respuestas.push( "S/C");;
    this.labels1 = [...this.preguntas[index].respuestas]; 
    this.labels1.push("S/C");
    
    this.data1 = [];
    for(var i = 0; i < this.labels1.length; i++){
      this.data1[i] = Number(0);
    }

    this.mostradas.push( idPregunta );
    
    console.log("Respuesta deseada : ", respuesta);
    this.wsService.emit('activar-pregunta', { 
                                                idPregunta: idPregunta, 
                                                pregunta: this.ultimaPreguntaPregunta, 
                                                idEncuesta: this.idEncuesta, 
                                                respuesta: respuesta , 
                                                respuestas: respuestas , 
                                                tiempo: tiempo 
                                              });
    this.segundos = duracion;

    this.intervalo = setInterval( () => { 
      this.segundos--;
    }, 1000 );

    setTimeout(() => {
      this.desactivarPregunta(idPregunta);
    }, duracion * 1000); 

  }

  desactivarPregunta(idPregunta){
    console.log("Desactivar pregunta " , idPregunta);
    clearInterval(this.intervalo);
    this.segundos = 0;
    this.wsService.emit('desactivar-pregunta',{ idPregunta: idPregunta }); 
  }
  activarEncuesta(){
    this.wsService.emit('activar-encuesta',{ idEncuesta: this.idEncuesta });  
    this.wsService.idEncuesta = this.idEncuesta;
  }

  cerrarEncuesta(){
    this.wsService.emit('cerrar-encuesta',{ }); 
    this.wsService.idEncuesta = '';
  }
 
  emitirValor(){
    this.onClick.emit('Neo');
    console.log("Se dio click al emitir");
  }

}
