<div class="card"> 
    <div class="card-body"> 
  
      <div class="row">
        <div class="form-group col-12 col-md-6">    
              <input type="text" name="edit-name-es" id="edit-name-es" class="form-control input-lg" placeholder="Filtrar" (keyup)="updateFilter($event)" /> 
        </div>  
        <div class="form-group col-12 col-md-6"> 
          <a [routerLink]="'/dashboard/formulario-encuesta'" class="btn btn-kala btn-block" ><i class="icofont icofont-ui-add"></i> Agregar</a>
        </div> 
      </div> 
      <div> 
        <ngx-datatable class="material data-table mt-3 mb-5" 
        style="height: 439px;"
        #myTable
        [rows]="rows" 
        [limit]="10"
        [columnMode]="'force'" 
        [headerHeight]="50" 
        [footerHeight]="50"
        [rowHeight]="50" 
        [scrollbarV]="true" 
        [scrollbarH]="true"
        [sorts]="[{prop: 'name', dir: 'desc'}]"
        >
  
        <ngx-datatable-column name="Descripción">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="wsService.idEncuesta == row._id" class="font-weight-bold">Activa - </span>
            {{row.nombre}}
          </ng-template>
        </ngx-datatable-column>
        
        <ngx-datatable-column name="Fecha">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.fecha}}
          </ng-template>
        </ngx-datatable-column>
    
        <ngx-datatable-column name="" [sortable]="false"  [cellClass]="'text-center m-0 p-0'">
          <ng-template let-row="row" ngx-datatable-cell-template > 
            <button type="button" class="btn btn-kala centrarBotonVertical" [routerLink]="['/dashboard/encuesta-activa',row._id]">Detalle</button>
            <button type="button" class="btn btn-kala centrarBotonVertical ml-2" [routerLink]="['/dashboard/graficas-encuesta',row._id]">Graficas</button>
          </ng-template>
        </ngx-datatable-column> 
  </ngx-datatable>
      </div>
    
  
  </div> 
  </div>