import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EncuestaService } from '../../services/encuesta.service';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { environment } from '../../../environments/environment';
const base_url = environment.base_url;

@Component({
  selector: 'app-graficas-encuesta',
  templateUrl: './graficas-encuesta.component.html'
})
export class GraficasEncuestaComponent implements OnInit {

  idEncuesta = ""; 

  listaPreguntas: any[] = [];
  listaRespuestas: any[] = []; 

  
  constructor( 
    private router: Router,
    private encuestaService:  EncuestaService,
    private route: ActivatedRoute,
    ) {
      this.idEncuesta = this.route.snapshot.paramMap.get('id');  
     }
     ngOnInit(): void {
      this.iniciar(); 
     }

     async iniciar(){
       await this.encuestaService.getResultadosEncuesta( this.idEncuesta ).subscribe( 
        (data) => { 
          console.log("Data getPreguntas " , data);
          this.listaPreguntas = data;
          for(let i = 0; i < this.listaPreguntas.length ; i++){
            this.listaRespuestas[i] = [
              { data: this.listaPreguntas[i].solucion , label: '', backgroundColor:  '#6857E6'}
            ]
          } 
          
        }, (err) => {
          console.log("Error getPreguntas | ",err);
        }); 
 
        
     }
    
     exportarExcel(){
      window.location.href = base_url + '/excel/' + this.idEncuesta ;  
     }

  parsearNumero ( valor: any) {
    return parseInt( valor );
  }
  sumaDatos( valores: number[]){
    let suma = 0;
    for(let i = 0; i < valores.length; i++){
      suma += valores[i];
    }
    return suma;
  }
}
