import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Pregunta } from '../../models/pregunta.model';
import { EncuestaService } from '../../services/encuesta.service';

@Component({
  selector: 'app-listar-preguntas',
  templateUrl: './listar-preguntas.component.html',
  styleUrls: ['./listar-preguntas.component.css']
})
export class ListarPreguntasComponent implements OnInit {

  preguntas:Pregunta[] = [];
  rows:Pregunta[] = [];
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent;

  constructor(
    private router: Router,
    private encuestaService: EncuestaService
  ) { }

  ngOnInit(
    
  ): void {
    this.encuestaService.getPreguntas().subscribe( 
      (data: any) => {  
      // cache our list
      console.log("Preguntas ", data)
      this.preguntas = [...data];  
      
      this.preguntas = [...data]; 
      // push our inital complete list
      this.rows = data;
    })
  }

  editarPregunta(idPregunta: string){
    console.log("idPregunta ", idPregunta);
    
    this.router.navigate(['/dashboard','editar-pregunta',idPregunta]);  
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const ltemp = this.preguntas.filter(( fila ) => { 
      return  fila.pregunta.toLowerCase().indexOf(val) !== -1 || 
              fila.categoria.toLowerCase().indexOf(val) !== -1 || 
              !val;
    });

    // update the rows
    this.rows = ltemp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }
}
