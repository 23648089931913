import { Component, OnInit, ViewChild, Input } from '@angular/core'; 
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label, BaseChartDirective } from 'ng2-charts'; 

@Component({
  selector: 'app-barras',
  templateUrl: './barras.component.html',
  styles: []
})
export class BarrasComponent implements OnInit {
  @ViewChild(BaseChartDirective) public chart: BaseChartDirective; 
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  @Input('labels')  barChartLabels: Label[] = ['0', '0', '0', '0', '0', '0', '0'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels]; 

  @Input('data') //barChartData2: ChartDataSets[];
  
  barChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: '', backgroundColor:  '#6857E6'}
  ];
  colors = [
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(0, 255, 0, 0.2)',
        'rgba(102, 0, 204, 0.2)',
        'rgba(255, 128, 0, 0.2)'
      ]
    }
    ];

  constructor() { }

  ngOnInit() {
    //console.log("Iniciando componente BAR ", this.barChartData2);
    //this.barChartData =  [ this.barChartData2 ];
  } 

  updateChart() {
      this.chart.chart.update(); // This re-renders the canvas element.
  }

}
