<div class="row animated fadeIn fast">

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h3>Usuarios</h3>

                <div class="alert alert-info animated fadeIn fast"
                    *ngIf="usuarios.length === 0">
                  <p class="mb-0">
                      No hay usuario con ese término
                  </p>
                </div>

                <div class="table-responsive"
                    *ngIf="usuarios.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Avatar</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let usuario of usuarios"
                                class="animated fadeIn fast">
                                <td>
                                    <img [src]="usuario.img | imagen:'usuarios'" 
                                         class="w75"/>
                                </td>
                                <td>{{ usuario.nombre }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h3>Hospitales</h3>

                <div class="alert alert-info animated fadeIn fast"
                    *ngIf="hospitales.length === 0">
                  <p class="mb-0">
                      No hay hospitales con ese término
                  </p>
                </div>

                <div class="table-responsive"
                    *ngIf="hospitales.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Foto</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let hospital of hospitales"
                                class="animated fadeIn fast">
                                <td>
                                    <img [src]="hospital.img | imagen:'hospitales'" 
                                         class="w75"/>
                                </td>
                                <td>{{ hospital.nombre }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h3>Médicos</h3>

                <div class="alert alert-info animated fadeIn fast"
                    *ngIf="medicos.length === 0">
                  <p class="mb-0">
                      No hay médicos con ese término
                  </p>
                </div>

                <div class="table-responsive"
                    *ngIf="medicos.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Foto</th>
                                <th>Médico</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let medico of medicos"
                                class="animated fadeIn fast">
                                <td>
                                    <img [src]="medico.img | imagen:'medicos'" 
                                         class="w75"/>
                                </td>
                                <td>
                                    <a [routerLink]="['/dashboard/medico', medico._id ]">
                                        {{ medico.nombre }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>
</div> 