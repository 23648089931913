import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modulos
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';

import { PipesModule } from '../pipes/pipes.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Grafica1Component } from './grafica1/grafica1.component';
import { PagesComponent } from './pages.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RxjsComponent } from './rxjs/rxjs.component';
import { PerfilComponent } from './perfil/perfil.component';
import { UsuariosComponent } from './mantenimientos/usuarios/usuarios.component';
import { HospitalesComponent } from './mantenimientos/hospitales/hospitales.component';
import { MedicosComponent } from './mantenimientos/medicos/medicos.component';
import { MedicoComponent } from './mantenimientos/medicos/medico.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { FormularioPreguntaComponent } from './formulario-pregunta/formulario-pregunta.component';
import { EditarPreguntaComponent } from './editar-pregunta/editar-pregunta.component';
import { ListarPreguntasComponent } from './listar-preguntas/listar-preguntas.component';
import { ListarEncuestasComponent } from './listar-encuestas/listar-encuestas.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormularioEncuestaComponent } from './formulario-encuesta/formulario-encuesta.component';
import { FormularioEditarPreguntaComponent } from './formulario-editar-pregunta/formulario-editar-pregunta.component';
import { GraficasEncuestaComponent } from './graficas-encuesta/graficas-encuesta.component'; 
 
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { EncuestaActivaComponent } from './encuesta-activa/encuesta-activa.component';
import { PresentarPreguntasComponent } from './presentar-preguntas/presentar-preguntas.component';

const config: SocketIoConfig = { 
  url: environment.base_url , 
  options: {} 
};


@NgModule({
  declarations: [
    DashboardComponent,
    ProgressComponent,
    Grafica1Component,
    PagesComponent,
    AccountSettingsComponent,
    PromesasComponent,
    RxjsComponent,
    PerfilComponent,
    UsuariosComponent,
    HospitalesComponent,
    MedicosComponent,
    MedicoComponent,
    BusquedaComponent,
    FormularioPreguntaComponent,
    EditarPreguntaComponent,
    ListarPreguntasComponent,
    ListarEncuestasComponent,
    FormularioEncuestaComponent,
    FormularioEditarPreguntaComponent,
    GraficasEncuestaComponent,
    EncuestaActivaComponent,
    PresentarPreguntasComponent,
  ],
  exports: [
    DashboardComponent,
    ProgressComponent,
    Grafica1Component,
    PagesComponent,
    AccountSettingsComponent
  ],
  imports: [ 
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    ComponentsModule,
    NgxDatatableModule,   
    PipesModule, 
    SocketIoModule.forRoot(config),
  ]
})
export class PagesModule { }
