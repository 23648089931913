import { Component, OnInit, ViewChild } from '@angular/core';
import { WebsocketsService } from '../../services/websockets.service'; 
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EncuestaService } from '../../services/encuesta.service';

@Component({
  selector: 'app-listar-encuestas',
  templateUrl: './listar-encuestas.component.html',
  styleUrls: ['./listar-encuestas.component.css']
})
export class ListarEncuestasComponent implements OnInit {

  title = 'angular-ngx-datatable-tutorial';

  temp = [];
  rows = [];

  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent;

  constructor(
    public wsService: WebsocketsService,
    private encuestasService: EncuestaService
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  
  fetch() {
    this.encuestasService.getEncuestas().subscribe( 
      (data: any) => {  
        //console.log("Encuesta " , data);
        // cache our list
        this.temp = [...data]; 
        // push our inital complete list
        this.rows = data;
    })  
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return  d.nombre.toLowerCase().indexOf(val) !== -1 || 
              d.fecha.toLowerCase().indexOf(val) !== -1 || 
              !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  activarEncuesta(){
    console.log("Activar la encuesta");
  }
}
