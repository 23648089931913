import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
 
import { EncuestaService } from '../../../../src/app/services/encuesta.service';     

@Component({
  selector: 'app-formulario-encuesta',
  templateUrl: './formulario-encuesta.component.html',
  styleUrls: ['./formulario-encuesta.component.css']
})
export class FormularioEncuestaComponent implements OnInit {

  public formSubmitted = false;
  public encuestaForm = this.fb.group({
    nombre:     ['' , [ Validators.required ] ],
    fecha:      ['', Validators.required ],
  } );

  constructor( 
    private router: Router,
    private encuestaService: EncuestaService,
    private fb: FormBuilder,   
  ) { }

  ngOnInit(): void {
  }
  crearEncuesta(){
    this.formSubmitted = true;
    console.log( this.encuestaForm.value );

    if ( this.encuestaForm.invalid ) {
      console.log("Form invalid");
      return;
    }
    
    this.encuestaService.crearEncuesta( this.encuestaForm.value )
      .subscribe( resp => {
        console.log(resp); 
        if( resp.ok ){
           alert("Correcto");
        }else{
          alert("Error");
        } 
        this.router.navigate(['/dashboard/listar-encuestas']); 
      }, err => {
        console.log("Error", err.error);  
      });

  }

  campoNoValido( campo: string ): boolean {
    
    if ( this.encuestaForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

}
