<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"> 
                        <i class="mdi mdi-account"></i> <span class="hide-menu">{{ usuario.nombre }} </span>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a routerLinkActive="active" routerLink="./perfil">Perfil</a></li>
                        <!--<li><a routerLink="account-settings">Colores</a></li>-->
                        <li><a routerLink="/login">Logout</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap"><i class="mdi mdi-gauge"></i> OPCIONES</li>
                <li >
                    <a [routerLink]="['/dashboard','listar-preguntas']"
                       routerLinkActive="active"
                       [routerLinkActiveOptions]="{ exact: true }"><i class="mdi mdi-comment-question-outline"></i> <span class="hide-menu">Preguntas</span></a>
                </li>
                <li >
                    <a [routerLink]="['/dashboard','listar-encuestas']"
                       routerLinkActive="active"
                       [routerLinkActiveOptions]="{ exact: true }"><i class="mdi mdi-format-list-bulleted"></i> <span class="hide-menu">Encuestas</span> </a>
                </li>
                <li >
                    <a [routerLink]="['/dashboard','presentar-encuesta']"
                       routerLinkActive="active"
                       [routerLinkActiveOptions]="{ exact: true }"><i class="mdi mdi-cast"></i> <span class="hide-menu">Presentar</span> </a>
                </li> 

                <li *ngFor="let item of sidebarService.menu"> 
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                        <i [class]="item.icono"></i>
                        <span class="hide-menu"> 
                            {{ item.titulo }} 
                            <span class="label label-rouded label-themecolor pull-right">
                                {{ item.submenu.length }}
                            </span>
                        </span>
                    </a>

                    <ul aria-expanded="false" class="collapse">
                        <li *ngFor="let subMenuItem of item.submenu">
                            <a [routerLink]="subMenuItem.url"
                               routerLinkActive="active"
                               [routerLinkActiveOptions]="{ exact: true }"> {{ subMenuItem.titulo }} </a>
                        </li>
                    </ul>
                </li>
                
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->