import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
}) 
export class WebsocketsService {

  public socketStatus = false;
  public idPregunta = '';
  public idEncuesta = '';

  constructor(
    private socket: Socket
  ) {
    console.log("Iniciando el socket");
    this.checkStatus(); 
   }
  checkStatus(){
    this.socket.on('connect', () => {
      this.socketStatus = true;
      console.log("Conectado al servidor ");
    });
    this.socket.on('disconnect', () => {
      this.socketStatus = false;
      console.log("Desconectado del servidor ");
    });
  }
  
  emit( evento: string, payload?: any, callback?: Function ) {

    console.log('Emitiendo', evento);
    // emit('EVENTO', payload, callback?)
    this.socket.emit( evento, payload, callback );

  }

  listen( evento: string ) {
    return this.socket.fromEvent( evento );
  }

  
  getPregunta() {
    return this.listen('pregunta-vista');
  }
  getApagarPregunta() {
    return this.listen('pregunta-ocultar');
  }
  
}
