<div class="row">
    <div class="col-6">
        <app-dona title="Ventas"></app-dona>
    </div>

    <div class="col-6">
        <app-dona [labels]="labels1"
                  [data]="data1"
                  title="Compras">
        </app-dona>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <app-dona title="Cambios"></app-dona>
    </div>

    <div class="col-6">
        <app-dona title="Compras 2"></app-dona>
    </div>
</div>