 
    <div style="display: block">
      <canvas baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend" 
        [colors]="colors"
        chartType="bar">
      </canvas>
    </div>