import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators'; 
import { Encuesta } from '../models/encuesta.model';
import { Pregunta } from '../models/pregunta.model';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class EncuestaService {

  constructor( private http: HttpClient ) { } 
  
  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }
 
  getEncuestaActiva(){
    const url = `${ base_url }/api/encuesta-activa`;
    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, idEncuesta: string, idPregunta: string }) => {  
                  return { idEncuesta: resp.idEncuesta, idPregunta: resp.idPregunta }
                })
              );
  }
  getEncuestas() { 
    const url = `${ base_url }/api/encuestas`;
    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, encuestas: Encuesta[] }) => { 
                  return resp.encuestas 
                })
              );
  }
  
  getPreguntas() { 
    const url = `${ base_url }/api/preguntas`;
    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, preguntas: Pregunta[] }) => {  
                  return resp.preguntas 
                })
              );
  }
  getResultadosEncuesta(idEncuesta: string) { 
    const url = `${ base_url }/api/respuestas/${ idEncuesta }`;
    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, respuestas: any[] }) => {  
                  return resp.respuestas 
                })
              );
  }
  crearEncuesta(formData: any ) {
    
    return this.http.post(`${ base_url }/api/encuestas`, formData )
              .pipe(
                tap( (resp: any) => resp)
              )

  }
  crearPregunta(formData: Pregunta ) {
    
    return this.http.post(`${ base_url }/api/preguntas`, formData )
              .pipe(
                tap( (resp: any) =>  resp)
              )

  }
  
  getPregunta( idPregunta:string ) { 
    const url = `${ base_url }/api/preguntas/${ idPregunta }`;

    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, pregunta: Pregunta }) => { 
                  console.log(resp.pregunta)
                  return resp.pregunta
                })
              );
  }

  actualizarPregunta(idPregunta: string, formData: Pregunta ) {
    
    return this.http.put(`${ base_url }/api/preguntas/${ idPregunta }`, formData )
              .pipe(
                tap( (resp: any) =>  resp)
              )

  }

  getRespuestas( idEncuesta:string ) { 
    const url = `${ base_url }/api/respuestas/${ idEncuesta }`;

    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, respuestas: any[] }) => { 
                  console.log(resp.respuestas)
                  return resp.respuestas
                })
              );
  }
  

}
