


<div id="main-wrapper">

    <app-header></app-header>

    <app-sidebar></app-sidebar>

    <div class="page-wrapper">
        <div class="container-fluid">
            <!-- Breadcrumbs -->
            <app-breadcrumbs></app-breadcrumbs>

            <!-- Mi sistema de rutas -->
            <router-outlet></router-outlet>

            <!-- 
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            
                        </div>
                    </div>
                </div>
            </div> 
            -->

        </div>

        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            © 2020 - Kalantaan
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->

    </div>


</div>
 