<div class="row">
    <div class="col-12 col-md-6"></div>
    <div class="col-12 col-md-6 mb-3">
        <button type="button" class="btn btn-success text-white w-100 h-100" (click)='exportarExcel()'>Exportar a excel</button>
    </div>
    <div class="col-12 col-md-6"  *ngFor="let pregunta of listaPreguntas; index as i">
        <div class="card">
            <div class="card-title">
                <h4 class="mt-3 text-center">{{ pregunta.pregunta.pregunta }}</h4> 
            </div>
            <div class="card-body">
                <div class="">
                    Correcta: <b>{{ pregunta.pregunta.respuestas[ parsearNumero(pregunta.pregunta.respuesta) ] }}</b>
                </div> 
                <app-barras 
                    #com1 
                    chartType="horizontalBar"
                    [labels]="pregunta.pregunta.respuestas"
                    [data]="listaRespuestas[i]" 
                ></app-barras>
                <div class="row mt-3">
                    <div class="col text-left">
                        Total contestados:
                    </div>
                    <div class="col">
                        <b>{{ pregunta.contestados }}</b>
                    </div>
                </div>  
        </div>
    </div>
</div>


