
<div class="card"> 
    <div class="card-body">
        <form
        [formGroup]="preguntaForm"
        (ngSubmit)="crearPregunta()"
        > 
            
            <div class="row">
                <div class="form-group col-12 ">
                    <label class="login-form-label">Número de pregunta </label>
                    <input type="text" name="new-pregunta" id="new-pregunta" class="form-control input-lg" placeholder="Orden" value="" 
                    formControlName="orden">
                    <p *ngIf="campoNoValido('orden')" class="text-danger error">* El número es obligatorio</p>
                </div>		
                <div class="form-group col-12 ">
                    <label class="login-form-label">Categoria </label>
                    <input type="text" name="new-categoria" id="new-categoria" class="form-control input-lg" placeholder="Categoria" value="" 
                    formControlName="categoria">
                    <p *ngIf="campoNoValido('categoria')" class="text-danger error">* La categoria es obligatoria</p>
                </div>	
                <div class="form-group col-12 ">
                    <label class="login-form-label">Pregunta </label>
                    <input type="text" name="new-pregunta" id="new-pregunta" class="form-control input-lg" placeholder="Pregunta" value="" 
                    formControlName="pregunta">
                    <p *ngIf="campoNoValido('pregunta')" class="text-danger error">* La pregunta es obligatoria</p>
                </div>		
                <div class="form-group col-12 ">
                    <label class="login-form-label">Duración (Segundos) </label>
                    <input type="text" name="new-pregunta" id="new-pregunta" class="form-control input-lg" placeholder="Segundos" value="" 
                    formControlName="duracion">
                    <p *ngIf="campoNoValido('duracion')" class="text-danger error">* La duración es obligatoria</p>
                </div>						
                <div class="form-group col-12 ">
                    <label class="login-form-label">Respuesta (Número de respuesta) </label>
                    <select  class="form-control input-lg"
                    formControlName="respuesta">
                        <option *ngFor="let control of respuestas.controls; let i = index" value="{{ i }}" > {{ i + 1 }} - {{ control.value }}</option>
                    </select> 
                    <p *ngIf="campoNoValido('respuesta')" class="text-danger error">* La respuesta es obligatoria</p>
                </div>  
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <button type="button" class="btn btn-success w-100" (click)="agregarRespuesta()">+ Añadir respuesta</button>
                </div>
            </div>
            <div class="row" formArrayName="respuestas"  *ngFor="let control of respuestas.controls; let i = index">
                <div class="form-group col-3 ">
                    <label class="login-form-label">Respuesta {{ i + 1 }}</label>
                </div>
                <div class="form-group col-6 ">
                    <input type="text" name="new-respuesta" id="new-respuesta" class="form-control input-lg" placeholder="Escriba respuesta" value="" 
                    [formControlName]="i"> 
                </div>
                <div class="col-3"> 
                    <button type="button" class="btn btn-danger w-100" (click)="quitarRespuesta(i)">Borrar</button>
                </div> 
            </div>
            <div class="row">
                <div class="form-group col-12 ">  
                    <a (click)="crearPregunta()" href="javascript:void(0)"  class="btn btn-primary btn-block" ><i class="ti-save"></i> Guardar</a>
                </div> 
            </div>
        </form>
    </div>
</div>