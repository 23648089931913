import { Component, OnInit, ViewChild } from '@angular/core';
import { WebsocketsService } from 'src/app/services/websockets.service';
import { EncuestaService } from 'src/app/services/encuesta.service';

@Component({
  selector: 'app-presentar-preguntas',
  templateUrl: './presentar-preguntas.component.html',
  styleUrls: ['./presentar-preguntas.component.css']
})
export class PresentarPreguntasComponent implements OnInit {
  @ViewChild("com1") com1: any;

  //Información para mostrar
  encuestaIniciada = false;
  idPregunta = 0;
  preguntasActivas = 0;
  pregunta: string = "";
  respuestas: any[] = [];
  respuestaActual = 0;
  segundos = 0;
  intervalo;

  //Resultados
  public labels1: string[] = ['', '', ''];
  public data1 = [ 0, 0, 0 ];
  public data2 = [{ data: [0, 0, 0, 0, 0, 0, 0], label: '', backgroundColor:  '#6857E6'}];

  constructor(
    public wsService: WebsocketsService,
    private encuestaService: EncuestaService,
  ) {
    this.wsService.listen('resultados-pregunta').subscribe( (data: any)=> {

      console.log('resultados-pregunta', data );
      //this.data1 = [...data];
      this.data2[0].data = [...data];
      this.labels1 = [...this.respuestas];
      this.labels1.push("S/C");
      try{

        this.com1.updateChart();

      }catch(error){

        console.log("presentar-preguntas | const | Error: ",error);

      }
      
    }, err => {

    });
    this.wsService.getPregunta().subscribe( (msg: any) => {
        console.log("Activar pregunta ", msg);
        this.encuestaIniciada = true;
        this.idPregunta = msg.idPregunta;
        this.pregunta = msg.pregunta;
        this.preguntasActivas = Number( msg.respuestas.length );
        this.respuestas = msg.respuestas;
        this.respuestaActual = msg.respuesta;

        this.segundos = msg.tiempo;

        this.intervalo = setInterval( () => { 
          this.segundos--;
        }, 1000 );

        setTimeout(() => {
          clearInterval(this.intervalo);
          this.segundos = 0;
        }, msg.tiempo * 1000); 
        
        }, err => {

    })
    this.wsService.getApagarPregunta().subscribe( msg => {
        console.log("Desactivar pregunta " );
        this.preguntasActivas = 0; 
    }, err => {
        this.preguntasActivas = 0; 
    });
   }

  ngOnInit(): void {
  }

}
