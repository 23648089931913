<div class="card">  
    <div class="card-body"> 
      <form
      [formGroup]="encuestaForm"
      (ngSubmit)="crearEncuesta()"
      > 
      <div class="row">
        <div class="form-group col-12 col-md-6">
            <label class="login-form-label">Nombre </label>
            <input type="text" name="new-nombre" id="new-nombre" class="form-control input-lg" placeholder="Nombre" value="" 
            formControlName="nombre">
            <p *ngIf="campoNoValido('nombre')" class="text-danger error">* El nombre es obligatorio</p>
        </div>						
        <div class="form-group col-12 col-md-6">
            <label class="login-form-label">Fecha </label>
            <input type="text" name="new-fechas" id="new-fechas" class="form-control input-lg" placeholder="Fecha" value=""
            formControlName="fecha">
            <p *ngIf="campoNoValido('fecha')" class="text-danger error">* La fecha es obligatoria</p>
        </div> 
        <div class="form-group col-12 col-md-12">  
          <a (click)="crearEncuesta()" href="javascript:void(0)"  class="btn btn-primary btn-block" ><i class="ti-save"></i> Guardar</a>
        </div> 
        </div>
      </form>
      </div>
  </div> 