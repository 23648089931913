import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';

import { EncuestaService } from '../../../../src/app/services/encuesta.service';    

@Component({
  selector: 'app-formulario-pregunta',
  templateUrl: './formulario-pregunta.component.html',
  styleUrls: ['./formulario-pregunta.component.css']
})
export class FormularioPreguntaComponent implements OnInit {

  public formSubmitted = false;
  public preguntaForm = this.fb.group({
    orden:        ['' , [ Validators.required ] ],
    categoria:    ['' , [ Validators.required ] ],
    pregunta:     ['' , [ Validators.required ] ],
    duracion:     ['' , [ Validators.required ] ],
    respuesta:    ['', Validators.required ],
    respuestas:   this.fb.array([])
  } );

  constructor(
    private router: Router,
    private encuestaService: EncuestaService,
    private fb: FormBuilder, 
  ) { }

  get respuestas(){
    return this.preguntaForm.get('respuestas') as FormArray;
  }

  ngOnInit(): void {
  }
  crearPregunta(){
    this.formSubmitted = true;
    console.log( this.preguntaForm.value );

    if ( this.preguntaForm.invalid ) {
      console.log("Form invalid");
      return;
    }
    this.encuestaService.crearPregunta( this.preguntaForm.value ).subscribe( resp => {
      console.log(resp); 
      if( resp.ok ){
         alert("Correcto");
      }else{
        alert("Error");
      } 
      this.router.navigate(['/dashboard/listar-preguntas']); 
    }, err => {
      console.log("Error", err.error);  
    }); 
  }

  campoNoValido( campo: string ): boolean {
    
    if ( this.preguntaForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  agregarRespuesta(){
    this.respuestas.push( this.fb.control('', Validators.required) )
  }

  quitarRespuesta(i: number){
    this.respuestas.removeAt(i);
  }
}
