
   
<div class="card">
    <div class="card-title"> 
        
        <div class="text-center mt-3">
            Conexión a servidor: 
            <span class="text-success font-weight-bold" *ngIf="wsService.socketStatus"><i class="mdi mdi-minus-circle"></i> - Online </span>
            <span class="text-danger font-weight-bold" *ngIf="!wsService.socketStatus"><i class="mdi mdi-minus-circle"></i> - Offline</span>  
        </div> 
        <div class="row m-3 ">
            
            <div class="form-group col-12 col-md-6" > 
                <button *ngIf="segundos != 0" type="button" class="btn btn-warning  btn-block" (click)="desactivarPregunta('abc')">Desactivar pregunta - {{ segundos }} segundos</button>
                <span *ngIf="segundos == 0">No hay pregunta activa</span>
            </div>
            <div class="form-group col-12 col-md-6" *ngIf="wsService.idEncuesta != idEncuesta"> 
              <a (click)="activarEncuesta()" href="javascript:void(0)" class="btn btn-success btn-block" >Activar encuesta <span *ngIf="wsService.idEncuesta != ''" class="font-weight-bold">Hay otra activa</span></a>
            </div> 
          <div class="form-group col-12 col-md-6" *ngIf="wsService.idEncuesta == idEncuesta"> 
            <a (click)="cerrarEncuesta()" href="javascript:void(0)" class="btn btn-danger btn-block" >Cerrar encuesta</a>
          </div> 
        </div> 
    </div>
    <div class="card-body"> 
        

        
      <div> 
        <ngx-datatable class="material data-table mt-3 mb-5" 
        style="height: 439px;"
        #myTable
        [rows]="preguntas" 
        [limit]="10"
        [columnMode]="'force'" 
        [headerHeight]="50" 
        [footerHeight]="50"
        [rowHeight]="50" 
        [scrollbarV]="true" 
        [scrollbarH]="true"
        [sorts]="[{prop: 'name', dir: 'desc'}]"
        >
  
        <ngx-datatable-column name="Orden">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.orden }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Orden">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span [className]="mostradas.includes(row._id) ? 'font-weight-bold' : ''">{{ row.pregunta }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Respuesta">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.respuestas[ row.respuesta ] }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Categoria">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.respuestas[ row.categoria ] }}
                  </ng-template>
                </ngx-datatable-column> 
                <ngx-datatable-column name="" [sortable]="false"  [cellClass]="'text-center m-0 p-0'">
                <ng-template let-row="row" ngx-datatable-cell-template > 
                    <button *ngIf="wsService.idEncuesta == idEncuesta && segundos == 0" type="button" class="btn btn-secondary btn-block" (click)="activarPregunta(row.orden , row._id, row.duracion, row.respuestas)"><span *ngIf="mostradas.includes(row._id)">Re-</span>Activar pregunta ( {{ row.duracion }} seg)</button> 
                    </ng-template>
                </ngx-datatable-column> 
        </ngx-datatable>
      </div>
    

        <table class="table">
            <thead>
                <tr>
                    <th class="text-center">No.</th>
                    <th class="text-center" >Título</th>
                    <th class="text-center" >Respuesta</th>
                    <th class="text-center">Duración</th>
                    <th class="text-center">Botones</th>
                </tr>
            </thead> 
            <tbody>
                <tr *ngIf="preguntas.length == 0">
                    <td colspan="3" class="text-center">
                        No hay preguntas
                    </td>
                </tr>
                <tr *ngFor="let pregunta of preguntas; let i = index">
                    <td>{{ pregunta.orden }}</td>
                    <td [className]="mostradas.includes(pregunta._id) ? 'font-weight-bold' : ''">{{ pregunta.pregunta }}</td>
                    <td >{{ pregunta.respuestas[ pregunta.respuesta ] }}</td>
                    <td>{{ pregunta.duracion }}</td>
                    <td>
                        <button *ngIf="wsService.idEncuesta == idEncuesta && segundos == 0" type="button" class="btn btn-secondary btn-block" (click)="activarPregunta(i , pregunta._id, pregunta.duracion, pregunta.respuestas)"><span *ngIf="mostradas.includes(pregunta._id)">Re-</span>Activar pregunta</button> 
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

 
<div class="card">
    <div class="card-title text-center mt-3"> 
        <h3>
            Última pregunta activa
        </h3>
    </div>
    <div class="card-body">
        Gráfica pregunta {{ ultimaPregunta_data.pregunta || '' }} 
        <app-barras #com1 
        [labels]="labels1"
        [data]="data2"
        ></app-barras>
        <hr>
        <app-dona title="{{ ultimaPregunta_data.pregunta || '' }}"
                [labels]="labels1"
                [data]="data1"
        ></app-dona>
    </div>
</div>